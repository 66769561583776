@mixin mobile_checkout_backbutton {
  #top {
    position: relative !important;
    box-shadow: none;
    #header {
      position: fixed;
      box-shadow: 0 8px 10px -5px $color-dark-grey;
      background: $color-white;
      .block-template-basic-responsive-v1 {
        display: block;
        visibility: visible;
        width: 100%;
        .mobile_checkout_backbutton {
          background: url('/media/export/cms/nav-arrows.png') left 9px;
          background-repeat: no-repeat;
          height: 50px;
          width: 30px;
          position: absolute;
          left: 16px;
          display: block;
        }
      }
      .block-nodeblock-4326,
      .block-template-site-logo-responsive-v1 {
        display: block;
        visibility: visible;
        width: 100%;
      }
    }
  }
}

@mixin mobile_checkout_footer {
  #footer {
    position: relative;
    padding-bottom: 0em;
    .footer-top {
      .footer-top-mobile-v1 {
        .social-links {
          display: none;
        }
      }
    }
    .footer-bottom {
      .block {
        text-align: center;
        .checkout_footer {
          @include swap_direction(padding, 10px 0px 0px);
          display: block;
        }
      }
      .field-mobile-menu {
        display: none;
      }
      .help-text {
        @include swap_direction(padding, 0px 0px 15px);
        @include swap_direction(margin, 0px 0px 10px);
        display: block;
        border-bottom: 1px solid $color-cl-medium-grey;
        font-size: 14px;
        color: $color-text-black;
        a.help-number {
          color: $color-cl-green-2;
        }
      }
      .lock-icon {
        display: block;
        font-size: 15px;
        span.fa.fa-lock {
          &::before {
            @include swap_direction(margin, 0px 5px);
            content: '\f023';
            font-family: 'FontAwesome';
          }
        }
      }
      .block-template-sticky-footer-chat-v3 {
        display: block;
        height: 35px;
      }
    }
  }
}

@mixin mobile-sticky-btn {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1002;
  width: 100%;
  background: $color-white;
  padding: 20px;
}

%panel-left-right {
  padding-left: 0;
  padding-right: 0;
}
%panel-left {
  padding-left: 1em;
}
%non-float {
  float: none;
}
%prod-height {
  height: 150px;
}
