body#confirm {
  div.footer-bottom {
    padding: 10px 0 10px 0;
  }
  .panel > .column {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .messages.error {
    background-color: $color-white;
    color: $color-notice-pink;
    padding: 0;
  }
  h4.checkout-header__title {
    @media screen and (max-width: $medium-l) {
      padding-top: 10px;
    }
    float: left;
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 23px;
    border-width: 0;
    margin: 0;
    line-height: 1.2em;
    color: $color-cl-soft-black;
  }
  @media screen and (max-width: $medium-l) {
    @include mobile_checkout_footer;
    @include mobile_checkout_backbutton;
    .main {
      #top {
        #header {
          .block-template-basic-responsive-v1 {
            .mobile_checkout_backbutton {
              display: none;
            }
          }
        }
      }
    }
    section#confirmation-page {
      @media screen and (min-width: $medium-l) {
        padding: 0 1em;
      }
    }
    .checkout-confirmation-page {
      .confirmation-panel {
        .banner {
          padding: 0 0 20px 0;
          margin: 0;
          img {
            width: 100%;
          }
        }
      }
      #guarantee-panel {
        border-top: 1px solid $color-shade-grey;
      }
      .social-login {
        &__container {
          padding: 0 1em;
        }
      }
    }
  }
  #guarantee-panel {
    margin: 2em 0;
    .content {
      padding-top: 1em;
    }
  }
  section {
    &#confirmation-page {
      @media screen and (min-width: $medium-l) {
        position: relative;
        max-width: 960px;
        margin: 0 auto;
        display: block;
      }
      padding-top: 5em;
      .links-panel {
        header {
          border-bottom: 1px solid $color-shade-grey;
          h2 {
            padding: 0 0 10px;
            font-weight: 400;
            color: $color-black;
          }
        }
        .content {
          padding: 0 10px;
        }
      }
    }
    &#confirmation-panel {
      .checkout-header {
        overflow: hidden;
        border-bottom: 1px solid $color-shade-grey;
      }
      .actions {
        margin-left: 2em;
        margin-bottom: 2em;
        .banner {
          padding: 0 0 20px 0;
          margin: 0;
          img {
            width: 100%;
          }
        }
      }
      h3,
      p {
        margin-bottom: 1em;
      }
    }
  }
  .confirmation-panel__container {
    @media screen and (max-width: $medium-l) {
      width: 100%;
    }
    margin: 0;
    width: 60%;
    float: left;
    .confirmation-panel {
      &__content {
        @media screen and (min-width: $medium-l) {
          padding: 1em 0 0 0;
        }
      }
      &__actions {
        margin: 0 !important;
        padding: 1em;
        overflow: hidden;
        width: 100%;
        @media screen and (min-width: $medium-l) {
          padding: 1em 0;
        }
      }
    }
  }
  .column.left {
    @media screen and (max-width: $medium-l) {
      float: none;
    }
  }
  .checkout__panel-content {
    padding: 0.4em 1em;
    @media screen and (min-width: $medium-l) {
      padding: 0.4em 0;
    }
  }
  .password {
    .form-item input {
      width: 100%;
    }
  }
  .sign-in-confirmation-panel {
    &__content {
      .submit {
        .btn {
          width: 100%;
          background-color: $color-cl-green-2;
          text-transform: uppercase;
          font-weight: 700;
          color: $color-white;
          font-size: 18px;
          height: 45px;
          border: 1px solid $color-cl-green-2;
          margin-top: 3px;
          border-radius: 0;
        }
      }
      .password input {
        width: 100%;
      }
    }
  }
  ul.links_list {
    padding-top: 1em;
  }
  .responsive-container {
    max-width: 960px;
    margin: 0 auto;
    padding-top: 0;
    overflow: visible;
    clear: both;
    .sign-in-confirmation-panel {
      &__title {
        color: $color-cl-soft-black;
        padding-left: 0;
        font-weight: 400;
        font-size: 23px;
      }
      &__content {
        @include start-breakpoint($medium-l) {
          padding: 0;
        }
        .btn {
          background: $color-cl-green;
          color: $color-white;
          padding: 5px;
        }
      }
    }
    .registration-panel {
      .checkout {
        &__panel-title,
        &__subtitle {
          color: $color-black;
          font-weight: 400;
          font-size: 23px;
          padding-left: 0;
        }
      }
      .how-we-will-use-container {
        .checkout__subtitle {
          font-size: 1.2em;
        }
      }
    }
  }
  form#completed_transaction_signin,
  .checkout-registration {
    .form-item {
      @media screen and (min-width: $medium-l) {
        width: 60%;
      }
    }
  }
}
