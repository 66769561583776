.elc-product-full {
  .elc-vto-foundation {
    .elc-vto {
      &-perfect-shade-label {
        font-family: $base-bolder-font-family;
        width: 70px;
      }
      &-foundation-shade-grid {
        &.vto-foundation-lip {
          .elc-vto-product-name {
            font-weight: 600;
          }
        }
      }
      &-tab-container {
        margin-bottom: 15px;
      }
    }
    .elc-price-formatted-wrapper {
      .elc-price-formatted {
        font-weight: 600;
      }
    }
    .elc-add-to-bag-button,
    .elc-product-notify-me-button {
      height: 48px;
      line-height: normal;
    }
  }
}
