.device-mobile {
  .field-mobile-template {
    .basic-content-mobile-v1 {
      .content {
        height: auto;
        max-height: 100%;
        table {
          width: 100% !important;
          tr {
            td {
              width: 50%;
            }
          }
        }
      }
    }
  }
  header {
    .site-logo-responsive-v1 {
      padding: 0;
    }
  }
  &.page-products-makeup-foundations {
    .basic-content-mobile-v1 {
      height: auto;
      margin-top: 0;
    }
  }
}

.mobile_mpp {
  ul {
    &#mpp-product-grid {
      li {
        &.mpp-product {
          overflow: unset;
          .product-info {
            p {
              &.price {
                span {
                  &.mpp_price {
                    display: block;
                    width: 100%;
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.contact_us {
  #contact_form_fields {
    .email_address1_container,
    .verify_email_address_container {
      input {
        width: 100%;
        padding: 4px 8px;
        border-radius: 0;
        border: 1px solid $color-shade-grey;
      }
    }
  }
}

.offer-promo {
  .link-based-offer-ref-v1 {
    .link-based-offer-mobile-v1 {
      .subheading h2 {
        display: none;
      }
    }
  }
}

body {
  #footer {
    .footer-bottom {
      .help-text {
        display: none;
      }
      .lock-icon {
        display: none;
      }
    }
  }
}

.disabledLink {
  pointer-events: none;
  cursor: default;
}

.form-submit {
  &.control-btns {
    height: 57px;
    margin-top: 15px;
    width: 100%;
  }
}
