@if $password_strength {
  .password-field {
    &__info {
      #signin & {
        @media #{$medium-up} {
          width: 95%;
          #{$ldirection}: 0;
          bottom: 50px;
        }
      }
      .checkout &,
      #confirm & {
        @media #{$medium-up} {
          position: absolute;
          #{$ldirection}: 202px;
          width: 200px;
          bottom: -39px;
          border: 1px solid $color-black;
        }
      }
      #password_reset & {
        @media #{$medium-up} {
          top: 0;
          width: auto;
          #{$ldirection}: 36%;
          -webkit-transform: translateY(-38px);
          -moz-transform: translateY(-38px);
          -o-transform: translateY(-38px);
          -ms-transform: translateY(-38px);
          transform: translateY(-38px);
        }
      }
      #confirm & {
        @media #{$medium-up} {
          #{$ldirection}: 180px;
          &:before {
            #{$ldirection}: -11px;
            top: 40%;
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
      }
    }
    &__rules {
      .checkout &,
      #confirm & {
        @media #{$medium-up} {
          column-count: 1;
        }
      }
    }
  }
  .change-password {
    fieldset.fs {
      overflow: visible;
    }
  }
}
