ul.spp_view {
  li.spp-product {
    .mpp_product {
      .product-info {
        float: none;
        p.price {
          span.spp_price {
            width: 100%;
            display: block;
            margin-bottom: 10px;
          }
        }
        .product-afterpay-installment {
          margin: 0px 15px;
          #product-afterpay-logo {
            background: url('/media/export/cms/afterpay/ap-logo.png') no-repeat;
            width: 100px;
            height: 25px;
            vertical-align: middle;
            background-size: 100px;
            float: left;
          }
          #afterpay-learnmore {
            float: right;
            a {
              color: $color-black;
            }
            text-transform: uppercase;
            text-decoration: underline;
            font-size: 12px;
          }
        }
      }
    }
  }
  .ingredients-section {
    padding: 15px;
    .ingredients_header {
      cursor: pointer;
    }
    .ingredients_content {
      padding-top: 3px;
    }
    .ingredients_more {
      background-image: url(/media/export/cms/images/toggle_plus.png);
    }
    .ingredients_less {
      background-image: url(/media/export/cms/images/minus_icon.png);
      margin-bottom: 4px;
    }
    .ingredients_toggle {
      display: inline-block;
      height: 10px;
      width: 10px;
    }
  }
}

.body_container #footer .footer-bottom {
  .afterpay-content,
  .afterpay-content-mb {
    display: none;
  }
}
