#customer_service_chat {
  margin-top: 50px;
  a {
    margin-top: 60px;
    display: block;
  }
}

.cs-3-col-row-v1 {
  .customer-care-list {
    .customer-care-list-item {
      .body-content {
        max-height: 200px;
      }
    }
  }
}

#findation-container-mobile {
  background-size: contain;
  height: 100px;
  min-height: 110px;
  position: relative;
  width: 100%;
  #findation-button-iframe {
    position: relative;
    top: 50px;
  }
}

#findation-widget-button {
  position: relative;
}

#findation-container {
  height: 100px;
  min-height: 110px;
  position: relative;
  width: 400px;
  #findation-button-iframe {
    bottom: 10px;
    position: absolute;
  }
  .button-inner {
    img {
      height: auto;
      width: 150px;
    }
  }
}

.signin_container {
  .login-now,
  .register-now {
    clear: both;
  }
}

.spp_page_wrap {
  .container {
    .product-full {
      &__media {
        vertical-align: top;
        display: inline-block;
        @media #{$medium-up} {
          width: 48%;
          display: inline-block;
          margin-bottom: 20px;
        }
        .product-full {
          &__image {
            display: inline-block;
            vertical-align: top;
            margin: 0 auto;
            position: relative;
            max-width: 402px;
            &-wrapper {
              display: inline-block;
              position: relative;
              vertical-align: top;
            }
            &--slide {
              @media #{$medium-up} {
                display: none;
              }
              &.active {
                @media #{$medium-up} {
                  display: block;
                }
              }
            }
            &--slide,
            &--slide img {
              width: 402px;
              height: 464px;
            }
            &__video {
              position: relative;
              &.slick-slide {
                img.mobile-hidden {
                  display: none;
                }
              }
              &-overlay {
                background-image: url('/media/images/icons/video_play.png');
                background-repeat: no-repeat;
                background-position: top right;
                padding: 20px;
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 100;
              }
              .poster-frame {
                width: 100%;
              }
            }
          }
          &__alt-images {
            display: inline-block;
            vertical-align: top;
            position: relative;
            &-slider {
              display: none;
              margin: 60px 20px 0 0;
              width: 50px;
              @media #{$medium-up} {
                display: none;
              }
              &.active {
                @media #{$medium-up} {
                  display: inline-block;
                }
              }
              .slick-slide {
                border: none;
              }
              .slick-arrow {
                height: 6px;
                width: 11px;
                left: 20px;
                position: relative;
                z-index: 100;
                padding: auto;
              }
              .slick-prev {
                top: -25px;
                background: url('/media/images/icons/spp_thumb_carousel_caret_up.png') no-repeat;
                &:before {
                  content: none;
                }
              }
              .slick-next {
                bottom: -25px;
                background: url('/media/images/icons/spp_thumb_carousel_caret_down.png') no-repeat;
                &:before {
                  content: none;
                }
              }
              &-button {
                height: 6px;
                width: 11px;
                margin: 0 auto;
                cursor: pointer;
              }
            }
            &-thumb {
              padding: 5px 0;
              cursor: pointer;
              border-bottom: 3px solid $color-white;
              &.active {
                border-color: $color-black;
              }
              img {
                width: 50px;
              }
            }
          }
          .badge {
            @include border-radius(50%);
            position: absolute;
            top: 50px;
            #{$ldirection}: 50px;
            display: table;
            width: 65px;
            height: 65px;
            text-align: center;
            color: $color-white;
            z-index: 1;
            padding: 0;
            line-height: 14px;
            font-family: $base-font-family;
            vertical-align: middle;
            margin: 0;
            font-size: 14px;
            text-transform: uppercase;
            &--large {
              top: 0;
              left: 0;
            }
            &.badge_1,
            &.badge_2,
            &.badge_3,
            &.badge_4,
            &.badge_5,
            &.badge_17,
            &.badge_92,
            &.badge_94,
            &.badge_96,
            &.badge_97 {
              background: $color-cl-grey;
            }
            &.badge_96 {
              font-size: 12px;
            }
            &.badge_30 {
              background: $color-cl-grey;
            }
            html.ie7 & {
              text-align: center;
            }
            .badge-inner {
              display: table-cell;
              vertical-align: middle;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.sticky_prod_select {
  .giftset_components {
    .product-size {
      &__non-shaded {
        display: none;
      }
    }
  }
}
