.email-signup-block {
  &__svgicon-checkbox::after {
    content: url('/sites/clinique/themes/cl_base/img/icons/src/checkbox-selected-v2.svg');
    display: none;
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
  input[type='checkbox']:checked {
    ~ label {
      .email-signup-block__svgicon-checkbox::after {
        display: block;
      }
      .svgicon--checkbox-selected {
        display: none;
      }
    }
  }
}
