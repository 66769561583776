$color_dcddde: #dcddde;

.section-store-locator {
  .store-locator-mobile-v1 {
    .locations_form_panel {
      .address_section_container {
        input {
          border: 1px solid $color_dcddde;
          color: $color-text-black;
          font-size: 15px;
          padding: 4px 0;
          margin: 8px 5px 12px 0;
          text-indent: 8px;
          width: 100%;
          height: 40px;
        }
        select {
          width: 100%;
          padding: 5px 13px;
          margin: 10px 0;
        }
        .city_container {
          .sbHolder {
            ul.sbOptions {
              max-height: 300px;
              overflow-y: scroll !important;
            }
          }
        }
        .distance_container {
          label {
            width: 100%;
            float: left;
          }
          select.distance {
            display: block;
            float: left;
            width: 65%;
          }
          p {
            float: left;
            margin: 14px 0 0 2px;
            width: 30%;
          }
        }
      }
      a.search_by_location {
        line-height: 34px;
      }
    }
  }
}
