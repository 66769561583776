.game-formatter {
  .game-randomizer {
    .game-overlay {
      &__win-box-image {
        float: none;
        @include start-breakpoint ($medium-l) {
          float: #{$ldirection};
        }
      }
      &__lose-remaining-tries {
        @include start-breakpoint ($medium-l) {
          @include swap_direction(margin, 36px 0 5px 25px);
        }
      }
    }
  }
}
