.device-mobile {
  .main_container {
    .counter_block {
      height: 70px;
      background-color: $color-black;
    }
    .counter_header {
      td {
        font-size: 25px;
      }
    }
  }
}
