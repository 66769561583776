.samples-page {
  padding: 1em;
  .viewcart-buttons-panel {
    width: 100%;
    text-align: right;
    display: none;
  }
  .samples-panel {
    h2,
    h3,
    h4 {
      color: $color-cl-soft-black;
    }
    ul {
      &.product-list {
        overflow: hidden;
        .product {
          border-bottom: 1px dotted $color-light-grey;
          &:last-child {
            border: none;
          }
        }
        > li {
          &.deactivate {
            color: $color-darker-grey;
          }
          h4 {
            margin-top: 1em;
          }
        }
        li {
          border-top: 1px solid $color-shade-grey;
          border-bottom: none;
          height: 117px;
          .product-img {
            float: left;
            margin-top: 15px;
            margin-right: 15px;
          }
          .details {
            float: none;
            margin-top: 15px;
            ul {
              &.skus {
                margin: 20px 0;
              }
            }
          }
          &:first-child {
            border-top: none;
          }
        }
      }
      &.skus {
        list-style: none;
        margin: 1em 0 0 0;
      }
    }
    .deactivate {
      .product-img img,
      label {
        opacity: 0.3;
      }
      .product-name {
        color: $color-light-grey;
        cursor: default;
      }
      .sample-select-button {
        background-color: $color-light-grey;
        cursor: default;
      }
    }
    .add-button {
      float: right;
      margin-bottom: 15px;
    }
    h3 {
      margin-bottom: 1em;
    }
    .swatch,
    .samples-panel input.sample-select {
      float: left;
      margin-right: 0.3em;
    }
    .details {
      margin-left: 110px;
    }
    .no-thanks {
      display: none;
    }
    input.sample-select {
      float: left;
      margin-right: 0.3em;
    }
    @include breakpoint($tablet-down) {
      .add-button {
        display: none;
      }
    }
  }
  section#samples-panel {
    &.sample-promo-panel {
      .samples-panel__content {
        .tray_section {
          display: none;
        }
        li {
          &.product {
            border: none;
            &.selected {
              border-color: $color-black;
              border-style: solid;
              border-width: 1px;
            }
            &.disabled-select {
              opacity: 0.5;
            }
          }
        }
      }
    }
    &.active {
      .samples-buttons {
        display: block;
        .continue-button-wrapper {
          @include mobile-sticky-btn;
        }
      }
    }
    @include breakpoint($tablet-down) {
      .samples-buttons {
        .continue-button-wrapper {
          background: none;
          padding: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}

body {
  &#samples {
    .pg_wrapper {
      .sample-promo-panel {
        h2.samples-panel__title {
          width: 100%;
          font-size: 32px;
          font-weight: 400;
          color: $color-cl-green-2;
        }
        .samples-panel__content {
          margin: 0 auto;
          max-width: 1070px;
          #tray_container {
            padding: 10px;
            position: relative;
            .tray_sample {
              width: 200px;
              line-height: 15px;
              font-size: 14px;
              float: left;
              margin-bottom: 10px;
              .tray_img {
                background: $color-white;
                border: 1px solid $color-light-grey;
                float: left;
                height: 120px;
                width: 100px;
                margin-right: 10px;
                img {
                  padding: 15px 0 0 10px;
                }
              }
              .prod_name {
                color: $product-color-price;
              }
              a.remove_tray_sample {
                position: relative;
                left: -30px;
              }
            }
            input {
              position: absolute;
              right: 1%;
              bottom: 10%;
            }
          }
          .samples_promo {
            margin-top: 0;
            ul {
              &.product-list {
                overflow: hidden;
                li {
                  &.product {
                    @include start-breakpoint($medium-min) {
                      width: 23.5%;
                    }
                    width: 45%;
                    float: left;
                    margin: 0 5px 5px;
                    text-align: center;
                    height: 380px;
                    clear: none;
                    border: none;
                    font-family: $base-font-family;
                    .product-img {
                      float: none;
                      margin: 0;
                      cursor: pointer;
                      margin-top: 10px;
                    }
                    &.selected {
                      border-color: $color-black;
                      border-style: solid;
                      border-width: 1px;
                    }
                    &.disabled-select {
                      opacity: 0.5;
                    }
                    .sample_product_name {
                      text-align: left;
                      margin: 0 30px 10px 30px;
                      font-size: 17px;
                      cursor: pointer;
                    }
                    .short_desc {
                      margin: 0 30px;
                      text-align: left;
                      cursor: pointer;
                    }
                    ul {
                      &.skus {
                        display: none;
                      }
                    }
                  }
                }
                .product-img {
                  float: left;
                  margin-top: 15px;
                  margin-bottom: 1em;
                  width: auto;
                  img {
                    width: 117px;
                    height: 135px;
                  }
                }
              }
            }
          }
        }
        .samples-buttons {
          .continue-button-wrapper {
            &.add-button {
              position: relative;
              text-align: right;
              padding: 30px;
              float: none;
            }
          }
        }
      }
      &.device-mobile {
        section {
          &.samples-page {
            padding: 1em;
          }
          #samples-panel {
            &.sample-promo-panel {
              .samples-panel {
                &__content {
                  .tray_section {
                    display: none;
                  }
                  li {
                    &.product {
                      border: none;
                      &.selected {
                        border-color: $color-black;
                        border-style: solid;
                        border-width: 1px;
                      }
                      &.disabled-select {
                        opacity: 0.5;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      #add-samples-message {
        padding: 11px 0;
      }
      section {
        &.samples-panel,
        &.sample-promo-panel {
          header {
            h2 {
              width: 100%;
              padding: 14px 0 8px 0;
              font-size: 23px;
              height: auto;
              color: $color-black;
              font-weight: 400;
            }
          }
          ul {
            &.product-list {
              li {
                &.product {
                  height: auto;
                  margin: 0;
                  &:before,
                  &:after {
                    display: none;
                  }
                }
              }
              > li {
                h4 {
                  margin-top: 0;
                }
              }
            }
            &.skus {
              margin: 5px 0 20px 0;
              .sample-select-button {
                background-color: $color-cl-green-2;
                color: $color-white;
                line-height: 1.5;
                padding: 5px 10px;
                font-weight: $font-weight-bold;
                &.selected {
                  border: solid 1px $color-cl-green-2;
                  color: $color-cl-green-2;
                  background-color: $color-white;
                }
              }
              .shade {
                margin-bottom: 10px;
              }
            }
          }
          .deactivate {
            .product-img {
              img {
                opacity: 0.3;
              }
            }
            label {
              opacity: 0.3;
            }
            .product-name {
              color: $color-light-grey;
              cursor: default;
            }
            .sample-select-button {
              background-color: $color-light-grey;
              cursor: default;
            }
          }
          .content {
            @extend %panel-left-right;
            p {
              margin-top: 0;
            }
          }
          .offer {
            h3,
            .remaining {
              padding-left: 0;
            }
          }
          .details {
            margin-top: 15px;
            float: none;
          }
          h3 {
            color: $color-cl-soft-black;
            margin-bottom: 0;
          }
          .product-list {
            li {
              &.product {
                border-top: 1px solid $color-shade-grey;
                border-bottom: none;
                &:nth-child(4) {
                  margin-top: 1px;
                }
                &:first-child {
                  border-top: none;
                }
                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
          footer {
            .btn {
              float: right;
            }
          }
        }
      }
    }
  }
}
